import {useState} from 'react';
import {Employee} from '../models/Employee.model';

export const useEmployeeAsync = () => {
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
    const changeEmployee = (employee: Employee) => {
        if (employee?.employeeNumber != selectedEmployee?.employeeNumber) {
            setSelectedEmployee(employee);
        }
    };
    return {
        selectedEmployee,
	    changeEmployee
    };
};