import React, {useEffect} from 'react';
import {EmployeeDropDown} from './EmployeeDropDown';
import {Dots} from '@zendeskgarden/react-loaders';
import {Employee} from '../../models/Employee.model';
import {Row} from '@zendeskgarden/react-grid';
import {useZendeskUserAsync} from '../../hooks/useZendeskUserAsync';

type ComponentProps = {
    onSelect: (employee: Employee) => void
    selectedEmployee: Employee;
}

export function EmployeeSelection({onSelect, selectedEmployee}: ComponentProps) {
    const {
        getTicketRequestUser,
        getTicketCollaboratorsUser,
        allZendeskUsers
    } = useZendeskUserAsync();

    useEffect(() => {
        getTicketRequestUser().then((res) => {
            onSelect(res);
        });
        void getTicketCollaboratorsUser();
    }, []);

    return (
        <>
            {
                (allZendeskUsers.length) ?
                    <Row>
                        <EmployeeDropDown
                            allZendeskUsers={allZendeskUsers}
                            selectedUser={selectedEmployee}
                            selectUser={onSelect} />
                    </Row>
                    :
                    <Dots size={24} />
            }
        </>
    );
}