import {useState} from 'react';
import {LaptopDetails} from '../models/Laptop.model';
import {getLaptops} from '../services/Laptop.service';

export const useLaptopAsync = () => {
    const [selectedLaptop, setSelectedLaptop] = useState<LaptopDetails>(null);
    const [isLaptopLoading, setIsLaptopLoading] = useState<boolean>(true);
    const [laptops, setLaptops] = useState<LaptopDetails[]>([]);

    const fetchLaptops = (employeeNumber: string) => {
        setIsLaptopLoading(true);
        getLaptops(employeeNumber).then((res) => {
            setLaptops(res);
            setSelectedLaptop(res[0]);
            setIsLaptopLoading(false);
        });
    };

    const changeSelectedLaptop = (laptop: LaptopDetails) => {
        if (laptop?.serialNumber != selectedLaptop?.serialNumber) {
            setSelectedLaptop(laptop);
        }
    };

    return {
        fetchLaptops,
        selectedLaptop,
        setSelectedLaptop,
        changeSelectedLaptop,
        isLaptopLoading,
        laptops,
    };

};