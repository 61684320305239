import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Combobox, Field, Hint, IComboboxProps, Label, Option} from '@zendeskgarden/react-dropdowns.next';
// @ts-ignore
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {Col, Row} from '@zendeskgarden/react-grid';
import {debounce} from 'lodash';
import {LaptopDetails} from '../../models/Laptop.model';
import {Dots} from '@zendeskgarden/react-loaders';

type ComponentProps = {
    laptops: LaptopDetails[]
    currentLaptop: LaptopDetails
    changeSelectedLaptop: (arg: LaptopDetails) => void
}

export function LaptopDropDown({laptops, changeSelectedLaptop, currentLaptop}: ComponentProps) {
    const [options, setOptions] = useState<LaptopDetails[]>(laptops);

    useEffect(() => {
        setOptions(laptops);
    }, [laptops]);

    const handleChange = useCallback<NonNullable<IComboboxProps['onChange']>>(({inputValue, selectionValue}) => {
        if (selectionValue != null) {
            changeSelectedLaptop(selectionValue as LaptopDetails);
        } else if (inputValue) {
            const filtered = laptops.filter((items) => items.serialNumber.toLowerCase().includes(inputValue.toLowerCase()) ||
              items.modelName.toLowerCase().includes(inputValue.toLowerCase()));
            setOptions(Array.from(new Set([...filtered, currentLaptop])));
        } else {
            setOptions(laptops);
        }
    }, [laptops, currentLaptop]);

    const debounceHandleChange = useMemo(() => debounce(handleChange, 10), [handleChange]);

    useEffect(() => {
        return () => debounceHandleChange.cancel();
    }, [debounceHandleChange]);

    const formatOption = (value: LaptopDetails) => (`${value.modelName || ''} (${value.serialNumber})`);

    return (
        <Row justifyContent="center" style={{width: '100%'}}>
            <Col sm={8}>
                <Field>
                    <Label>Choose Laptop</Label>
                    <Hint data-testid='laptop-search-hint'>Only In use, In transit and Under repair laptops are shown</Hint>
                    <Combobox style={{width: '70%'}}
                        isDisabled={options.length === 0}
                        isAutocomplete
                        startIcon={<SearchIcon />}
                        key={currentLaptop?.serialNumber}
                        onChange={debounceHandleChange}>
                        {options.length === 0 ? (
                            <Option isSelected value="No Laptop Found!"/>
                        ) : (
                            options.map(value =>
                                <Option key={value.serialNumber} value={value} label={formatOption(value)}
                                    isSelected={Boolean(value.serialNumber == currentLaptop?.serialNumber)}>
                                </Option>)
                        )}
                    </Combobox>
                </Field>
            </Col>
        </Row>
    );
}
