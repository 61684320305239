import {Grid} from '@zendeskgarden/react-grid';
import React from 'react';
import {InformationIterm} from './InformationItem';
import {LaptopDetails} from '../../models/Laptop.model';
import {camelCase, startCase} from 'lodash';

type LaptopInformationProps = {
	information: LaptopDetails
    isShow: boolean
}

const LaptopInformationConfigList: { label: string, key: keyof LaptopDetails }[] = [
    {label: 'Model Name', key: 'modelName'},
    {label: 'Serial Number', key: 'serialNumber'},
    {label: 'State', key: 'state'},
    {label: 'Sub State', key: 'subState'},
    {label: 'Assignment Type', key: 'assignmentType'},
    {label: 'Account/Project', key: 'accountProject'},
    {label: 'Assigned Date', key: 'assignedDate'},
    {label: 'Purchased Date', key: 'purchasedDate'},
    {label: 'Purchased Location', key: 'purchasedLocation'},
    {label: 'Replacement Date', key: 'replacementDate'},
];

export const LaptopInformation = ({information, isShow}: LaptopInformationProps) => {

    const format = (key: keyof LaptopDetails, value: LaptopDetails): string => {
        return key === 'assignmentType' ? startCase(camelCase(value[key])) : value[key];
    };

    return isShow
        ? <Grid gutters={false} data-testid="laptop-information-detail" style={{overflowY: 'auto', marginTop: '2vh'}}>
            {LaptopInformationConfigList.map((config) =>
                <InformationIterm key={config.key} label={config.label} value={information ? format(config.key, information) : ''}/>)}
        </Grid>
        : <></>;
};
