import {constructUrl, HttpClient} from '../helper/HttpClient';

export type linkTrackInformation = {
    selectedUser: string
    clickedUser: string;
    laptop: string
}

export async function track(information: linkTrackInformation): Promise<string> {
    return await new HttpClient().post(constructUrl('linkTrack'), JSON.stringify(information));
}