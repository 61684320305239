import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {onAppRegistered} from './resources/zendesk';

//@ts-ignore
import ZendeskClient from './zaf';

function renderRoot(element: JSX.Element) {
    ReactDOM.render(element, document.getElementById('device-assistant-app'));
}

function initialise(module: any) {
    // @ts-ignore
    const client = initZendeskClient();
    if (client) {
        //eslint-disable-next-line
        (window as any)['ZendeskClient'] = client;
        onAppRegistered(function () {
            renderRoot(<App/>);
        });
    } else {
        renderRoot(<h1>Problem loading app...</h1>);
    }
}

function initZendeskClient(): ZendeskClient | unknown {
    if (document.defaultView && (document.defaultView as never)['ZAFClient']) {
        return ((document.defaultView as never)['ZAFClient'] as typeof ZendeskClient).init();
    }
}

setTimeout(initialise, 500);
