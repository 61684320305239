import {LaptopDetailResponse, LaptopDetails} from '../models/Laptop.model';
import {constructUrl, HttpClient} from '../helper/HttpClient';

export async function getLaptops(employeeNumber: string): Promise<LaptopDetails[]> {
    const url = constructUrl(`laptops?employeeNumber=${employeeNumber}`);
    const httpClient = new HttpClient();
    const response = await httpClient.get<LaptopDetailResponse[]>(url);
    return response.map((item) => ({
        sysId: item.sys_id,
        manufacturer: item.model_information.manufacturer,
        modelName: item.model_information.name || '',
        serialNumber: item.serial_number,
        state: item.state,
        subState: item.sub_state,
        assignmentType: item.financial_information.assignment_type,
        accountProject: item.financial_information.project_name,
        assignedDate: item.assignment_information.assigned_on,
        purchasedDate: item.financial_information.purchased_date,
        purchasedLocation: `${item.financial_information.purchased_location.name} - ${item.financial_information.purchased_location.country}`,
        replacementDate: item.replacement_information.date
    }));
}
