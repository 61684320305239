import React, {useMemo} from 'react';
import {Alert, Paragraph, Title} from '@zendeskgarden/react-notifications';
import {Span} from '@zendeskgarden/react-typography';

type Props = {
	email: string
}

const ErrorEmployeeMessage = ({email}: Props) => (
    <Alert type="error" style={{height: 119, width: 218}} data-testid="error-alert-display">
	  <Title>Oops!!</Title>
	  <Paragraph data-testid="error-message-display">
		  We cannot load the information for the user <Span
                isBold>{email}</Span>
	  </Paragraph>
    </Alert>
);

export default ErrorEmployeeMessage;

