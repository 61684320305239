import React from 'react';
import {Col, Row} from '@zendeskgarden/react-grid';
import {MD, Span} from '@zendeskgarden/react-typography';
import {useTheme} from 'styled-components';

type InformationItermProps = {
	label: string
	value: string
	rowSize?: number
}
const DEFAULT_PLACEHOLDER = 'N/A';
export const InformationIterm = ({label, value, rowSize = 12}: InformationItermProps): JSX.Element => {

    const theme = useTheme() as any;
    return (
	  <Row style={{marginBottom: 8}}>
            <MD style={{color: theme?.colors.foreground}}>
                <Span isBold>{label}: </Span>
                <Span>{value || DEFAULT_PLACEHOLDER}</Span>
            </MD>
	  </Row>
    );
};
