import {useMemo, useState} from 'react';
import {Employee} from '../models/Employee.model';
import {getCurrentUser, getTicketCollaborators, getTicketRequester} from '../resources/zendesk';
import {search} from '../services/employee.service';
import {transToEmployee} from '../helper/util';
import {User} from '../zaf';

export const useZendeskUserAsync = () => {
    const [collaborators, setCollaborators] = useState<Employee[]>([]);
    const [requester, setRequester] = useState<Employee>();
    const [isRequesterLoading, setIsRequesterLoading] = useState(true);
    const [isCollaboratorsLoading, setIsCollaboratorsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState<Employee>();

    const getEmployeeNumber = async (email: string): Promise<string> => {
        if (!email) return '';
        try {
            const response = await search(email);
            const employee = response.find((user) => user.email === email);
            return employee?.employeeNumber || '';
        } catch (err) {
            console.log(err);
            return '';
        }
    };

    const getTicketRequestUser = async () => {
	    setIsRequesterLoading(true);
        const response = await getTicketRequester();
        response.employeeNumber = await getEmployeeNumber(response.email);
        const formatUser = transToEmployee(response);
        setRequester(formatUser);
        setIsRequesterLoading(false);
	    return formatUser;
    };


    const getTicketCollaboratorsUser = async () => {
        setIsCollaboratorsLoading(true);
        const collaborators = await  getTicketCollaborators();
        const updatedCollaborators = await Promise.all(collaborators.map(async (user: User): Promise<Employee> => {
            user.employeeNumber = await getEmployeeNumber(user.email);
            return transToEmployee(user);

        }));
        setCollaborators(updatedCollaborators);
        setIsCollaboratorsLoading(false);
    };

    const allZendeskUsers: Employee[] = useMemo(() => {
        if (isCollaboratorsLoading || isRequesterLoading) return [];
        return  [requester, ...collaborators];
    }, [requester, collaborators, isCollaboratorsLoading, isRequesterLoading]);

    const getCurrentUserInfo = async () => {
        const user = await getCurrentUser();
        user.employeeNumber = await getEmployeeNumber(user.email);
        setCurrentUser(user);
        return user;
    };

    return {
        getTicketRequestUser,
        getTicketCollaboratorsUser,
        allZendeskUsers,
        currentUser,
        getCurrentUserInfo
    };
};