import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Combobox, Field, Hint, IComboboxProps, Label, Option} from '@zendeskgarden/react-dropdowns.next';
// @ts-ignore
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {Col, Row} from '@zendeskgarden/react-grid';
import {debounce} from 'lodash';
import {Employee} from '../../models/Employee.model';
import {search} from '../../services/employee.service';
import ErrorEmployeeMessage from '../error-alert';

type ComponentProps = {
    allZendeskUsers: Employee[];
    selectedUser: Employee;
    selectUser: (arg: Employee) => void;
}

const regexSearchEmployeeNumber = /\d{5,}/;

export function EmployeeDropDown({selectUser, selectedUser, allZendeskUsers}: ComponentProps) {
    const [options, setOptions] = useState<Employee[]>(allZendeskUsers);
    const [isCurrentUserError, setIsCurrentUserError] = useState(false);

    const formatLabel = (user: Employee) => (`${user?.name} (${user?.employeeNumber || 'N/A'})`);

    useEffect(() => {
        if (selectedUser?.employeeNumber) {
            setIsCurrentUserError(false);
        } else {
            setIsCurrentUserError(true);
        }
    }, [selectedUser]);

    const handleChange = useCallback<NonNullable<IComboboxProps['onChange']>>(async ({inputValue, selectionValue}) => {
        if (selectionValue != null) {
            selectUser(selectionValue as Employee);
        } else if (inputValue) {
            let filtered = allZendeskUsers.filter((items) => items.employeeNumber.includes(inputValue) ||
              items.name.toLowerCase().includes(inputValue.toLowerCase()));
            if (!filtered.length && inputValue.match(regexSearchEmployeeNumber)) {
                filtered = await search(inputValue);
            }
            setOptions(filtered);
        } else {
            setOptions(allZendeskUsers);
        }
    }, [allZendeskUsers, selectedUser?.employeeNumber]);


    const debounceHandleChange = useMemo(() => debounce(handleChange, 10), [handleChange]);

    useEffect(() => {
        return () => debounceHandleChange.cancel();
    }, [debounceHandleChange]);

    return (
        <>
            <Row style={{ marginBottom: '12px', display: 'block', width: '100%' }}>
                <Row justifyContent="center">
                    <Col sm={8}>
                        <Field>
                            <Label>Choose Employee</Label>
                            <Hint data-testid={'user-search-hint'}>Only Requester and cc’ed employees are shown</Hint>
                            <Combobox style={{width: '70%'}}
                                startIcon={<SearchIcon />}
                                key={selectedUser?.employeeNumber}
                                isAutocomplete
                                onChange={debounceHandleChange}>
                                {
                                    options.map(value =>
                                        <Option key={value.name + '-' + selectedUser?.name} value={value}
                                            label={formatLabel(value)}
                                            isSelected={value.name === selectedUser?.name}
                                        >
                                            <span>{formatLabel(value)}</span>
                                        </Option>)
                                }
                            </Combobox>
                        </Field>
                    </Col>
                </Row>
            </Row>
            {isCurrentUserError && <ErrorEmployeeMessage email={selectedUser?.email}/>}
        </>
    );
}
