import React, {useMemo} from 'react';
import {LaptopDropDown} from './LaptopDropDown';
import {LaptopDetails} from '../../models/Laptop.model';
// @ts-ignore
import {ReactComponent as NavigatorIcon} from '@zendeskgarden/svg-icons/src/16/new-window-stroke.svg';
import {Dots} from '@zendeskgarden/react-loaders';
import {Anchor} from '@zendeskgarden/react-buttons';
import {Row} from '@zendeskgarden/react-grid';
import {track} from '../../services/linkTrack.service';
import {Employee} from '../../models/Employee.model';

type ComponentProps = {
	isLoading: boolean
	laptops: LaptopDetails[]
	currentUser: Employee
	selectedEmployee: Employee
	currentLaptop: LaptopDetails
	changeSelectedLaptop: (laptop: LaptopDetails) => void
}

export function LaptopSelection({   currentUser,
	                                isLoading,
	                                laptops,
	                                selectedEmployee,
	                                changeSelectedLaptop,
	                                currentLaptop
}: ComponentProps) {

    const baseUrl = process.env.SERVICENOW_BASE_URL + '/now/nav/ui/classic/params/target/alm_hardware.do';


    const linkToServiceNow = useMemo(() => {
        const param = encodeURIComponent(`?sys_id=${currentLaptop?.sysId}`);
        return baseUrl + param;
    }, [currentLaptop]);

    const handleClickLink = () => {
        void track({
            clickedUser: `${currentUser?.email}(${currentUser?.employeeNumber})`,
            selectedUser: `${selectedEmployee.email}(${selectedEmployee.employeeNumber})`,
            laptop: `${currentLaptop.manufacturer} - ${currentLaptop.modelName || ''} - (${currentLaptop.serialNumber})`
        });
    };

    return (
	  !isLoading ?
            <Row>
                <LaptopDropDown laptops={laptops} changeSelectedLaptop={changeSelectedLaptop}
			                currentLaptop={currentLaptop}/>
	            {laptops.length > 0 && <div style={{margin: '2vh 0'}}>
                    <Anchor
	                   data-testid={'link-to-serviceNow'}
	                    style={{
                            color: '#68737D',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '3vw',
                            fontWeight: 400
                        }}
				        href={linkToServiceNow} target="_blank" onClick={handleClickLink}>
                        <NavigatorIcon/> View in ServiceNow
                    </Anchor>
                </div>}
            </Row>
            :
            <Dots size={24}/>
    )
	  ;
}
