import React, {useEffect} from 'react';
import {Chrome} from '@zendeskgarden/react-chrome';
import {ThemeProvider} from '@zendeskgarden/react-theming';
import {Grid} from '@zendeskgarden/react-grid';
import {EmployeeSelection} from './employee-selection';
import {LaptopSelection} from './laptop-selection';
import {LaptopInformation} from './laptop-information';
import {useLaptopAsync} from '../hooks/useLaptopAsync';
import {useEmployeeAsync} from '../hooks/useEmployeeAsync';
import {useZendeskUserAsync} from '../hooks/useZendeskUserAsync';
import {trackUser} from '../services/userTrack.service';


export default function AppComponent() {
    const {currentUser, getCurrentUserInfo} = useZendeskUserAsync();
    const {
        fetchLaptops,
        changeSelectedLaptop,
        selectedLaptop,
        laptops,
        isLaptopLoading
    } = useLaptopAsync();

    const {
        selectedEmployee,
        changeEmployee
    } = useEmployeeAsync();

    useEffect(() => {
        getCurrentUserInfo().then((res) => {
            void trackUser({
                name: res.name,
                email: res.email,
                employeeNumber: res.employeeNumber
            });
        });
    }, []);

    useEffect(() => {
        if (!selectedEmployee?.employeeNumber) return;
        changeSelectedLaptop(null);
        fetchLaptops(selectedEmployee.employeeNumber);
    }, [selectedEmployee?.employeeNumber]);

    return (
        <div style={{width: '100vw', marginLeft: -8}} data-testid={'app'}>
            <ThemeProvider>
                <Chrome style={{overflowY: 'auto'}}>
                    <Grid gutters={false}>
                        <EmployeeSelection selectedEmployee={selectedEmployee} onSelect={changeEmployee}/>
                        {
                            selectedEmployee?.employeeNumber &&
	                        (<>
	                            <LaptopSelection laptops={laptops} isLoading={isLaptopLoading} changeSelectedLaptop={changeSelectedLaptop}
	                                selectedEmployee={selectedEmployee}
	                                currentUser={currentUser}
	                                currentLaptop={selectedLaptop}/>
	                            <LaptopInformation information={selectedLaptop} isShow={Boolean(!isLaptopLoading && laptops.length > 0)} key={selectedLaptop?.serialNumber}/>
	                        </>)
                        }
                    </Grid>
                </Chrome>
            </ThemeProvider>
        </div>
    )
    ;
}
